import React from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";

import {
  ListItemButton,
  ListItemText,

  ListItemIcon,
  Link,
} from "@mui/material";
import { blue, orange } from "../theme/iso-colors";
import MarketStatistics from "./MarketStatistics";
import InfoIcon from '@mui/icons-material/Info';

function Home({ data }) {
  const openPDF = () => {
    // Replace 'path_to_your_pdf_file.pdf' with the actual path to your PDF file
    const pdfUrl = "OASIS_Data_Portal_User_Guide_v1.pdf";
    window.open(pdfUrl, "_blank");
  };

  return (
    <Box>
      <Grid container spacing={2}>
        <Grid item xs={8} md={6}>
          <Stack spacing={2} direction="column">
            <Typography variant="welcome">
            Welcome to the California ISO Open Access Same-time Information System (OASIS) Group Data site. On the OASIS Group Data site users will find pricing data related to the ISO Market, such as Day-Ahead and Real-Time market prices. The OASIS Group Data site is not intended to be used as an operational tool.
            </Typography>

            <Typography variant="body1">
              <Link onClick={openPDF} tabIndex={0}>
                Getting started with OASIS Group Data
                <ListItemIcon>
                  <OpenInNewIcon fontSize="small" />
                </ListItemIcon>
              </Link>
            </Typography>
            <Stack direction="column">
              <Typography variant="h4"> Standards Information</Typography>

              <ListItemButton
                component="a"
                target="_blank"
                href="https://www.naesb.org/"
              >
                <ListItemText
                  sx={{ color: blue[500] }}
                  variant="link"
                  primary="North American Energy Standards Board (NAESB)"
                />
              </ListItemButton>
              <ListItemButton
                component="a"
                target="_blank"
                href="https://www.caiso.com/rules/Pages/BusinessPracticeManuals/Default.aspx"
              >
                <ListItemText
                  sx={{ color: blue[500] }}
                  variant="link"
                  primary="ISO Business Practice Manuals"
                />
              </ListItemButton>
              <ListItemButton
                component="a"
                target="_blank"
                href="https://www.caiso.com/Pages/documentsbygroup.aspx?GroupID=F421A31B-4F81-4879-A981-E3A47770C7AB"
              >
                <ListItemText
                  sx={{ color: blue[500] }}
                  variant="link"
                  primary=" Available Transfer Capability Information"
                />
              </ListItemButton>
            </Stack>

            <Stack direction="column">
              <Typography variant="h4"> Transmission Information</Typography>
              <ListItemButton
                component="a"
                target="_blank"
                href="https://mpp.caiso.com/tp/Pages/default.aspx"
              >
                <ListItemText
                  sx={{ color: blue[500] }}
                  variant="link"
                  primary="Base Case Studys"
                />
              </ListItemButton>
              <ListItemButton
                component="a"
                target="_blank"
                href="https://www.caiso.com/planning/Pages/GeneratorInterconnection/Default.aspx"
              >
                <ListItemText
                  sx={{ color: blue[500] }}
                  variant="link"
                  primary="Interconnection Study Statistics"
                />
              </ListItemButton>
            </Stack>
          </Stack>
          <br />
          <Typography variant="h4"> System Help</Typography>
          <ul>
            <li>
              All technical specifications and artifacts for OASIS are available
              on the <a href="https://developer.caiso.com/pages/application.aspx?app=OASIS" target="_blank" rel="noopener noreferrer">ISO Developer site</a>. Self-registration is required to access
              the site.
            </li>
            <li>
              To download data without using the OASIS interface, see How to use
              report URLs to download OASIS data on the <a href="https://developer.caiso.com/pages/application.aspx?app=OASIS" target="_blank" rel="noopener noreferrer">ISO Developer site</a>.
            </li>
            <li>
              Access non-technical OASIS reference documents on{" "}
              <a href="https://www.caiso.com" target="_blank" rel="noopener noreferrer">www.caiso.com</a>
            </li>
          </ul>
          <br/>
          {/* <Alert icon = {<InfoIcon fontSize="inherit" />}variant="filled" severity="warning">
            <AlertTitle><Typography variant="h4" sx={{ color: orange[50] }}> Note</Typography></AlertTitle>
              <Typography variant="welcome">
                The historical data backfill process is currently underway for the years 2016 to 2020. The expected completion date is January 15, 2025. If the process finishes earlier we will remove the note and send a public communication.  
              </Typography>    
          </Alert> */}
        </Grid>

        <Grid item xs={8} md={6}>
          <Typography variant="h1" align="center" gutterBottom>
            Data Metrics
          </Typography>
          <MarketStatistics data={data} />
        </Grid>
      </Grid>
    </Box>
  );
}
export default Home;
